import axios from "@/axios";

class ContactServiceBrain {
    async getContactsToStatus(body) {
        const data = await axios.post(`/api/contact/get-contacts-by-status`, body)
        return data;
    }
    async updateStatus(body) {
        const data = await axios.put(`/api/contact/update-status`, body)
        return data;
    }
    async saveContactByTournaments(body) {
        const data = await axios.post(`/api/contact/save-organizer-by-tournaments`, body)
        return data;
    }
    async deleteContactById(body) {
        const data = await axios.put(`/api/contact/delete-by-id`, body)
        return data;
    }
    async updateContactById(body) {
        const data = await axios.put(`/api/contact/update-by-id`, body)
        return data;
    }
}
export default new ContactServiceBrain()