<template>
	<div>
		<b-sidebar
			backdrop
			width="40rem"
			title="Agregar torneo"
			body-class="bg-white"
			footer-class="px-2 py-1"
			v-model="isActive"
			@hidden="handleHidden"
		>
 			<b-overlay class="p-2" :show="isLoading">
				<ValidationObserver ref="form">
					<b-row>
						<b-col cols="12">
							<b-row>
								<b-col cols="12" md="6">
									<b-form-group>
										<template #label>
											<h4 class="m-0 title-form">1. Nombre de torneo</h4>
										</template>
										<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
											<b-form-input
												class="border-primary"
												placeholder="Agregar nombre del torneo"
												v-model="tournament.name"
												@input="tournament.name = tournament.name.toUpperCase()"
											/>
											<small class="text-danger">
												{{ errors[0] }}
											</small>
										</ValidationProvider>
									</b-form-group>
								</b-col>
								<b-col cols="12" md="6">
									<b-form-group>
										<template #label>
											<h4 class="m-0 title-form">2. Premio total</h4>
										</template>
										<div class="groupmoney">
											<v-select
												class="bg-white rounded"
												style="width: 4rem"
												label="name"
												:options="arrayCurrency"
												:reduce="(opt) => opt.id"
												:clearable="false"
												v-model="tournament.currency"
											/>
											<ValidationProvider rules="required|zero" v-slot="{ errors }" class="w-100">
												<money
													v-bind="money"
													v-model="tournament.total_prize"
													class="form-control border-primary mr-50"
													placeholder="Agregar precio"
													:style="{
														width: '100%',
														textAlign: 'center',
													}"
												/>
												<small class="text-danger">
													{{ errors[0] }}
												</small>
											</ValidationProvider>
										</div>
									</b-form-group>
								</b-col>
							</b-row>
						</b-col>

						<b-col cols="12" class="mt-2">
							<b-form-group>
								<template #label>
									<h4 class="m-0 title-form">3. Descripción</h4>
								</template>
								<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
									<b-form-input
										class="border-primary"
										v-model="tournament.description"
										placeholder="Agregar descripción"
										rows="3"
										max-rows="6"
									/>
									<small class="text-danger">
										{{ errors[0] }}
									</small>
								</ValidationProvider>
							</b-form-group>
						</b-col>
						<b-col cols="12" class="mt-2">
							<b-form-group>
								<template #label>
									<h4 class="m-0 title-form">4. Fechas</h4>
								</template>
								<b-row>
									<b-col>
										<b-form-group>
											<template #label>Inauguracion</template>
											<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
												<b-form-datepicker
													class="border-primary"
													locale="en-US"
													placeholder="Agregar inauguracion"
													:date-format-options="{
														month: 'numeric',
														day: 'numeric',
														year: 'numeric',
													}"
													v-model="tournament.start_date"
												/>
												<small class="text-danger">
													{{ errors[0] }}
												</small>
											</ValidationProvider>
										</b-form-group>
									</b-col>
									<b-col>
										<b-form-group>
											<template #label>Final</template>
											<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
												<b-form-datepicker
													class="border-primary"
													locale="en-US"
													placeholder="Agregar final"
													:date-format-options="{
														month: 'numeric',
														day: 'numeric',
														year: 'numeric',
													}"
													v-model="tournament.end_date"
												/>
												<small class="text-danger">
													{{ errors[0] }}
												</small>
											</ValidationProvider>
										</b-form-group>
									</b-col>
								</b-row>
							</b-form-group>
						</b-col>
						<b-col cols="12" class="mt-2">
							<b-form-group>
								<template #label>
									<h4 class="m-0 title-form">
										5. Etapas
										<b-button
											class="float-right"
											size="sm"
											variant="primary"
											@click="stageTypesModal.show = !stageTypesModal.show"
										>
											<feather-icon icon="Edit3Icon" size="20" />
										</b-button>
									</h4>
								</template>
							</b-form-group>
							<b-row>
								<b-col class="text-right" cols="5"><h4>Etapa</h4></b-col>
								<b-col cols="2"></b-col>
								<b-col cols="5"><h4>Cantidad de tipos</h4></b-col>
							</b-row>
							<b-row>
								<b-col class="text-right" cols="5">
									<span class="font-weight-bold">Pre-clasificación</span>
								</b-col>
								<b-col cols="2"></b-col>
								<b-col cols="5">{{ filterStages(1).length }}</b-col>
							</b-row>
							<b-row>
								<b-col class="text-right" cols="5">
									<span class="font-weight-bold">Clasificación</span>
								</b-col>
								<b-col cols="2"></b-col>
								<b-col cols="5">{{ filterStages(2).length }}</b-col>
							</b-row>
							<b-row>
								<b-col class="text-right" cols="5">
									<span class="font-weight-bold">Semifinal</span>
								</b-col>
								<b-col cols="2"></b-col>
								<b-col cols="5">{{ filterStages(3).length }}</b-col>
							</b-row>
							<b-row>
								<b-col class="text-right" cols="5">
									<span class="font-weight-bold">Final</span>
								</b-col>
								<b-col cols="2"></b-col>
								<b-col cols="5">{{ filterStages(4).length }}</b-col>
							</b-row>
						</b-col>
						<b-col cols="12" class="mt-2">
							<b-form-group>
								<template #label>
									<h4 class="m-0 title-form">
										6. Coliseo(s)
										<b-button
											class="btn-icon float-right"
											variant="outline-success"
											size="sm"
											@click="createColiseumModal.show = !createColiseumModal.show"
										>
											<feather-icon icon="PlusIcon" />
										</b-button>
									</h4>
								</template>
								<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
									<v-select
										@option:selecting="($event) => selectColiseum($event)"
										multiple
										label="name"
										:options="arrayColiseums"
										:reduce="(opt) => opt.value"
										v-model="tournament.coliseums"
										placeholder="Seleccionar coliseos"
										class="w-100 border-primary rounded"
									/>
									<small class="text-danger">
										{{ errors[0] }}
									</small>
								</ValidationProvider>
								<small class="mt-50" v-if="tournament.coliseums.length > 0">
									Seleccione un coliseo principal
								</small>
								<div class="d-flex flex-wrap">
									<b-form-checkbox
										v-for="(rd, index) in tournament.coliseumsCheck"
										class="mr-1 mt-05"
										:key="index"
										v-model="rd.check"
										name="checkbox-1"
										:value="true"
										:unchecked-value="false"
										@change="selectCheckColiseum(index)"
									>
										{{ arrayColiseums.find((it) => it.value == rd.id).name }}
									</b-form-checkbox>
								</div>
							</b-form-group>
						</b-col>
						<b-col cols="12" class="mt-2">
							<b-form-group>
								<template #label>
									<h4 class="m-0 title-form">
										7. Organizadores
										<CreateContact
											simple
											class="float-right"
											@new-record="handleNewRecords($event, 'organizer')"
										/>
									</h4>
								</template>
								<v-select
									multiple
									label="name"
									:options="arrayOrganizers"
									:reduce="(opt) => opt.value"
									v-model="tournament.organizers"
									placeholder="Seleccionar organizadores"
									class="w-100 border-primary"
								/>
							</b-form-group>
						</b-col>
						<b-col cols="12" class="mt-2">
							<b-form-group>
								<template #label>
									<h4 class="m-0 title-form">8. Comentario</h4>
								</template>
								<b-form-textarea
									class="border-primary"
									v-model="tournament.comment"
									placeholder="Agregar comentario"
									rows="3"
									max-rows="6"
								/>
							</b-form-group>
						</b-col>

						<b-col cols="12" class="mt-2">
							<b-form-group>
								<template #label>
									<h4 class="m-0 title-form">9. Flyers</h4>
								</template>
								<SelectableImages v-model="tournament.flyers" />
							</b-form-group>
						</b-col>
						<b-col cols="12" class="mt-2">
							<b-form-group>
								<template #label>
									<h4 class="m-0 title-form">10. Avatar</h4>
								</template>
								<ValidationProvider rules="required" v-slot="{ errors }" class="w-30">
									<div class="container-avatar">
										<InputImages
											:multiple="false"
											single
											variant="primary"
											v-model="tournament.avatar"
										/>

										<div class="icons-camera" @click="openModalImage()">
											<feather-icon icon="PlusIcon" style="color: white" />
										</div>
									</div>
									<small class="text-danger" v-if="errors[0]">
										{{ errors[0] }}
									</small>
								</ValidationProvider>
							</b-form-group>
						</b-col>
					</b-row>
				</ValidationObserver>
			</b-overlay>
			<template #footer>
				<b-button class="float-right" variant="success" @click="saveTournament">Guardar</b-button>
			</template>
			<StageTypes
				:show="stageTypesModal.show"
				:info="stageTypesModal.info"
				@closing="stageTypesModal.show = false"
				@new-types="setStages"
			/>
			<b-modal
				centered
				id="add-coliseum-modal"
				@ok.prevent="$refs.addColiseum.saveColiseum()"
				v-model="createColiseumModal.show"
				title="Crear coliseo"
			>
				<AddColiseum ref="addColiseum" @new-record="handleNewRecords($event, 'coliseum')" />
			</b-modal>
		</b-sidebar>
		<ModalSelectImages v-if="showModalImages" @close="showModalImages = false" @getImage="getImage" />
	</div>
</template>

<script>
import { bus } from "@/main"
import { Money } from "v-money"
import { getBase64 } from "@/helpers/functions"
import coliseumService from "@/services/tournament/coliseum.service"
import contactService from "@/services/tournament/contacts.service"
import tournamentService from "@/services/tournament/default.service"
import generalTournamentService from "@/services/tournament/general.service"
import ModalSelectImages from "@/views/amg/tournaments/v2/components/ModalSelectImages.vue"
import SelectableImages from "./SelectableImages.vue"
import StageTypes from "./StageTypes.vue"
import CreateContact from "@/views/brain/tournament/views/contacts/components/CreateContactBrain.vue"
import AddColiseum from "@/views/brain/tournament/views/coliseums/AddColiseum.vue"
import InputImages from "@/components/commons/InputImages.vue"

export default {
	name: "AddTournament",
	components: { SelectableImages, Money, StageTypes, CreateContact, AddColiseum, InputImages, ModalSelectImages },
	props: {
		show: Boolean,
		info: Object, // Preparation season
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		tournament: {
			name: "",
			start_date: "",
			end_date: "",
			description: "",
			stages: [],
			coliseums: [],
			coliseumsCheck: [],
			organizers: [],
			total_prize: 0,
			comment: "",
			flyers: [],
			avatar: [],
			idImage: null,
			currency: 2,
		},
		arrayColiseums: [],
		arrayOrganizers: [],
		arrayGeneralTournaments: [],
		money: {
			decimal: ".",
			thousands: ",",
			// prefix: "S./ ",
			suffix: "",
			precision: 2,
			masked: false,
		},
		stageTypesModal: {
			show: false,
			info: { stages: [] },
		},
		createColiseumModal: {
			show: false,
		},
		showModalImages: false,
		arrayCurrency: [
			{
				id: 2,
				name: "S/.",
			},
			{
				id: 1,
				name: "$",
			},
		],
	}),
	async mounted() {
		this.isPreloading()
		this.isActive = true
		await this.initTournamentGeneral()
		await this.initColiseums()
		await this.initOrganizers()
		this.isPreloading(false)
	},
	methods: {
		openModalImage() {
			this.showModalImages = true
		},

		getImage(val) {
			this.tournament.avatar = [
				{
					id: val.id,
					url: val.image,
				},
			]
		},
		handleHidden() {
			this.isActive = false
			this.$emit("closing")
		},
		async initColiseums() {
			const {
				data: { data },
			} = await coliseumService.getColiseumsToSelect({
				campo: null,
				pageIn: 1,
				perPageIn: 1000,
				status: 0,
			})
			this.arrayColiseums = data.map((it) => ({
				value: it.id_coliseum,
				name: it.name,
			}))
		},
		async initOrganizers() {
			const {
				data: { data },
			} = await contactService.getContactsToStatus({
				campo: null,
				pageIn: 1,
				perPageIn: 10000,
				status: 0,
			})
			this.arrayOrganizers = data.map((it) => ({
				value: it.id,
				name: it.name,
			}))
		},

		selectColiseum(item) {
			console.log(item)
			const exist = this.tournament.coliseums.some((it) => it == item.value)
			if (exist) return
			this.tournament.coliseumsCheck.push({
				id: item.value,
				check: false,
			})
			if (this.tournament.coliseumsCheck.length == 1) this.tournament.coliseumsCheck[0].check = true
		},
		selectCheckColiseum(index) {
			this.tournament.coliseumsCheck = this.tournament.coliseumsCheck.map((it) => ({
				...it,
				check: false,
			}))
			this.tournament.coliseumsCheck[index].check = true
		},
		async saveTournament() {
			if (!(await this.$refs.form.validate())) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campos por llenar"
				)
				return
			}
			this.isPreloading()
			// get avatar base64
			let avatarBase
			if (!this.tournament.avatar[0].id) {
				avatarBase = await getBase64(this.tournament.avatar[0].file)
			}
			//
			const tournamentSave = {
				...this.tournament,
				coliseums: this.tournament.coliseumsCheck.map((it) => ({
					id: it.id,
					main: it.check ? 1 : 0,
					currency_id:it.currency
				})),
				stages: this.tournament.stages,
				flyers: this.tournament.flyers.map((it) => ({
					...it,
					main: it.main ? 1 : 0,
					id: null,
				})),

				avatar: {
					id: this.tournament.avatar[0].id ? this.tournament.avatar[0].id : null,
					image: avatarBase ? avatarBase : null,
				},

				// avatar: this.tournament.avatar[0].id ? this.tournament.avatar[0].id : avatarBase
			}

			delete tournamentSave.coliseumsCheck
			await tournamentService.store(tournamentSave)
			this.handleHidden()
			this.isPreloading(false)
			this.showToast("success", "top-right", "Torneos", "CheckIcon", "Nuevo torneo creado exitosamente")
			this.$emit("refresh")
			bus.$emit("reload-tournaments")
		},
		async initTournamentGeneral() {
			const { data } = await generalTournamentService.index()
			this.arrayGeneralTournaments = data
			this.$refs.form.validate()
		},
		setStages(stages) {
			this.tournament.stages = stages.filter((s) => !s.delete)
			this.stageTypesModal.info = { stages, _mode: "create" }
		},
		filterStages(type) {
			return this.tournament.stages.filter((s) => s.stage_id == type)
		},
		async handleNewRecords(e, type) {
			this.isLoading = true
			if (type == "coliseum") {
				this.createColiseumModal.show = false
				await this.initColiseums()
				let justCreated = this.arrayColiseums.find((opt) => opt.value == e)
				if (justCreated) this.selectColiseum(justCreated)
				this.tournament.coliseums.push(e)
			} else if (type == "organizer") {
				await this.initOrganizers()
				this.tournament.organizers.push(e)
			}
			this.isLoading = false
		},
	},

	watch: {
		show(val) {
			if (val) {
				this.isActive = val
			}
		},
	},
}
</script>

<style scoped lang="scss">
.title-form {
	font-family: "Rubik" !important;
	font-size: 20px;
}
.icons-camera {
	width: 30px;
	height: 30px;
	background-color: #7367f0;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	border-radius: 5px;
	cursor: pointer;
}

.container-avatar {
	display: flex;
	align-items: center;
}

.Sau {
	display: flex;
	gap: 1rem;
	min-width: 7rem;
	max-width: 16rem;
	padding-top: 1rem;
	overflow-x: auto;

	&__box {
		flex-shrink: 0;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 6rem;
		height: 7rem;
		border-radius: 0.5rem;
		border: 2px solid var(--primary);
		gap: 1rem;
		margin-top: 1rem;
		margin-right: 1rem;

		&--dashed {
			border-style: dashed;
			border-color: var(--secondary);
		}
	}

	&__indication {
		text-align: center;
		font-size: 0.9rem;
	}

	&__input {
		position: absolute;
		opacity: 0;
		inset: 0;
		border: 2px solid pink;
		cursor: pointer;
	}

	&__img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
		cursor: pointer;
	}

	&__delete {
		position: absolute;
		top: -0.5rem;
		right: -0.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 1.5rem;
		height: 1.5rem;
		background: var(--danger);
		color: var(--white);
		border-radius: 50%;
		cursor: pointer;
	}
}
.groupmoney {
	display: flex;
}
</style>
