<template>
	<b-modal
		ref="modal-Create"
		@hidden="$emit('close')"
		cancel-title="Cancelar"
		scrollable
		size="md"
		no-close-on-backdrop
		title="Torneos"
	>
		<b-table-simple hover responsive >
			<b-thead>
				<b-tr>
					<b-th style="text-align: center"  >Nombre</b-th>
					<b-th style="text-align: center"  >Fecha</b-th>
				</b-tr>
			</b-thead>
			<template v-for="(item, index) in arrayTournamen">
				<b-tr :key="index">
 					<b-th style="text-align: center">{{ item.name }}</b-th>
					 <b-th style="text-align: center">{{ item.date   | myGlobalDayShort }}</b-th>

				</b-tr>
			</template>
		</b-table-simple>

		<template #modal-footer>
			<b-button variant="danger" @click="closeModal">Cerrar</b-button>
		</template>
	</b-modal>
</template>
<script>
import modalMixin from "@/mixins/modal.js"
import Ripple from "vue-ripple-directive"
export default {
	props: {
		arrayTournamen: Array,
	},
	directives: { Ripple },

	mixins: [modalMixin],
	data() {
		return {}
	},
	async mounted() {
		this.toggleModal("modal-Create")
	},

	methods: {
		closeModal() {
			this.$emit("close")
		},
	},
}
</script>
<style>
.container-imagess {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	gap: 1rem;
}
.container-check {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
</style>
