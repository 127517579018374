// import { amgApi } from "@/service/axios";

import axios from "@/axios";

class ColiseumService {
    async saveColiseum(body) {
        const data = await axios.post(`/api/coliseum/save`, body)
        return data;
    }
    async getColiseumsToSelect(body) {
        const data = await axios.post(`/api/coliseum/get-to-select`, body)
        return data;
    }
    async toggleStatusColiseum(body) {
        const data = await axios.put(`/api/coliseum/toggle-status`, body)
        return data;
    }
    async deleteColiseum(body) {
        const data = await axios.put(`/api/coliseum/delete`, body)
        return data;
    }
    async updateColiseum(body) {
        const data = await axios.put(`/api/coliseum/update`, body)
        return data;
    }
    
}
export default new ColiseumService()
