<template>
	<div>
		<b-modal
			ref="modal-Create"
			@hidden="$emit('close')"
			cancel-title="Cancelar"
			scrollable
			size="lg"
			no-close-on-backdrop
			title="Seleccione un avatar"
		>
			<div class="container-imagess">
				<div class="image-card" v-for="image in arrayImages" :class="image.selected ? 'card-opacity' : ''">
					<div class="cards">
						<ViewImage
							:specimen="{
								id: image.id,
								url: image.url_image,
							}"
						/>

						<div class="container-check">
							<div @click="openModal(image.tournaments)">
								<div class="icon-eyess" style="border-radius: 5px; padding: 2px">
									{{ image.tournaments.length }}
								</div>
							</div>
						</div>
					</div>
					<div class="radio-button" @click="selectImage(image.id)">
						<div class="radio-button-icon" v-if="image.selected"></div>
					</div>
				</div>
			</div>
			<template #modal-footer>
				<b-button variant="danger" @click="closeModal">Cancelar</b-button>
				<b-button variant="primary" @click="save">Agregar</b-button>
			</template>
		</b-modal>

		<ModalInfoAvatar v-if="showInfo" @close="showInfo = false" :arrayTournamen="arrayTournamen" />
	</div>
</template>
<script>
import modalMixin from "@/mixins/modal.js"
import Ripple from "vue-ripple-directive"
import generalTournamentService from "@/services/tournament/general.service"
import ViewImage from "@/components/commons/ViewImage.vue"
import ModalInfoAvatar from "@/views/amg/tournaments/v2/components/ModalInfoAvatar.vue"
export default {
	props: {
		idImage: null,
	},

	directives: { Ripple },
	components: {
		ViewImage,
		ModalInfoAvatar,
	},
	mixins: [modalMixin],
	data() {
		return {
			arrayImages: [],
			showInfo: false,
			arrayTournamen: [],
		}
	},
	async mounted() {
		this.toggleModal("modal-Create")
	},

	async created() {
		this.isPreloading()
		try {
			await this.getImages()
		} catch (error) {
			console.log("error: ", error)
		} finally {
			this.isPreloading(false)
		}
	},
	methods: {
		openModal(data) {
			this.arrayTournamen = data

			this.showInfo = true
		},
		async getImages() {
			const { data } = await generalTournamentService.indexImages()
			this.arrayImages = data.map((image, index) => ({
				...image,
				selected: false,
				// index: index,
			}))
		},
		closeModal() {
			this.$emit("close")
		},

		selectImage(selectedId) {
			this.arrayImages.forEach((image) => {
				if (image.id === selectedId) {
					image.selected = true
				} else {
					image.selected = false
				}
			})
		},
		save() {
			const filteredIds = this.arrayImages
				.filter((image) => {
					return image.selected === true
				})
				.map((image) => {
					return {
						id: image.id,
						image: image.url_image,
						url_thumb: image.url_thumb,
					}
				})

			const image = filteredIds[0]

			if (!image) {
				this.showToast(
					"warning",
					"top-right",
					"Seleccionar un Avatar",
					"AlertCircleIcon",
					"debes seleccionar un avatar"
				)
				return
			}

			this.$emit("getImage", image)
			this.$emit("close")
		},
	},
}
</script>
<style>
.checks {
	width: 20px;
	height: 20px;
	border-radius: 100%;
	background-color: red;
	display: flex;
	align-items: center;
	overflow: hidden;
	justify-content: center;
}

.cards {
	display: flex;
	padding-top: 1rem;
	padding-bottom: 1rem;
	gap: 1.5rem;

	padding-left: 2rem;
	padding-right: 2rem;
}
.image-card {
	display: flex;
	gap: 1rem;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
	border-radius: 5px;
	justify-content: center;
	position: relative;
}

.radio-button {
	position: absolute;
	top: 0.3rem;
	right: 0.3rem;
	background-color: #e3e3e3;
	width: 20px;
	height: 20px;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.radio-button-icon {
	width: 10px;
	height: 10px;
	background-color: #7367f0;
	border-radius: 100%;
}

.card-opacity {
	outline: 1.5px solid #7367f0;
}
.container-imagess {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 2rem;
}
.container-check {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 0.8rem;
}
.icon-eyess {
	width: 1.3rem;
	height: 1.3rem;
	background-color: #ea5455;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: -0.5rem;
	font-size: 0.9rem;
	color: white;
	font-weight: 800;
	cursor: pointer;
}
</style>
