<template>
	<div>
		<div class="container-select-images" @drop.prevent="drop($event)">
			<input
				type="file"
				ref="selectableImages"
				accept=".jpeg,.jpg,.png,.heif,.heic,.pdf"
				@change="fileChange($event.target)"
				hidden
				multiple
			/>
 			<div class="w-100 border-primary rounded bg-light" style="height: 18rem">
				<img class="image-flyer" :src="mainImage.file ? mainImage.file : mainImage.path" v-if="mainImage" />
			</div>

			<div class="d-grid">
				<b-row style="max-height: 19rem; overflow-y: auto">
					<b-col class="mb-1" cols="12" md="6">
						<div
							class="d-flex flex-column justify-content-center align-items-center text-primary border-primary rounded bg-light clickable"
							style="height: 8rem"
							@click="addImages"
						>
							<feather-icon icon="PlusIcon" size="25" />
							<br />
							<span class="text-center">Agregar flyer</span>
						</div>
					</b-col>
					<template v-for="(image, i) in images">

 						<b-col class="mb-1" cols="12" md="6" :key="i" v-if="!image.delete">
 							<div class="position-relative border-primary rounded bg-light" style="height: 8rem">
								<img class="image-flyer" :src="image.file ? image.file : image.path" />
								<b-badge
									class="position-absolute text-success clickable"
									style="top: 0.5rem; left: 0.5rem; background: #ffffff96"
									@click="checkImage(i)"
								>
									<feather-icon icon="CheckCircleIcon" size="20" />
								</b-badge>
								<b-badge
									class="position-absolute text-danger clickable"
									style="top: 0.5rem; right: 0.5rem; background: #ffffff96"
									@click="removeFlyer(i)"
								>
									<feather-icon icon="Trash2Icon" size="20" />
								</b-badge>
							</div>
						</b-col>
					</template>
				</b-row>
			</div>
		</div>
	</div>
</template>

<script>
import { getBase64 } from "@/helpers/functions"
import heic2any from "heic2any"
export default {
	name: "SelectableImages",
	props: {
		value: {
			type: Array,
			default: [],
		},
	},
	data: () => ({
		images: [],
	}),
	computed: {
		lengthImages() {
			return this.value.length
		},
		mainImage() {
			return this.images.find((img) => img.main)
		},
	},
	methods: {
		async drop(e) {
			this.isPreloading()
			e.stopPropagation()
			let files = e.dataTransfer.files
			for (let index = 0; index < files.length; index++) {
				await this.fileReader(files[index])
			}
			this.isPreloading(false)
		},
		async fileChange(e) {
			// console.log(e.files);
			// this.fileReader(e.files)

			this.isPreloading()
			let files = e.files
			for (let index = 0; index < files.length; index++) {
				await this.fileReader(files[index])
			}
			this.isPreloading(false)
		},
		async fileReader(file) {
			let newFile = file

			if (!["image", "application"].includes(file.type.split("/")[0])) {
				this.showToast("warning", "top-right", "Imagenes", "WarningIcon", "Solo se debe seleccionar imagenes.")
				return
			}
			if (["image/heif", "image/heic"].includes(file.type)) {
				await heic2any({ blob: file, toType: "image/jpg", quality: 1 }).then((newImage) => {
					newFile = new File([newImage], "heic" + ".jpg", {
						type: "image/jpeg",
						lastModified: new Date().getTime(),
					})
				})
			}

			let imagePath = await getBase64(newFile)

			if (file.type === "application/pdf") {
				imagePath = "https://imprenta.wiki/wp-content/uploads/2017/10/PDF-300x294.jpg"
			}

			this.images = [
				...this.images,
				{
					file: imagePath,
					path: await getBase64(newFile),
					main: false,
					delete: false,
				},
			]
			// if (this.images.length == 1) this.images[0].main = true

			this.$emit("input", this.images)
		},
		removeFlyer(index) {
			this.images[index].delete = true
			this.images[index].main = false
			const existMain = this.images.some((it) => it.main)
			const activeImages = this.images.filter((it) => !it.delete)
			if (!existMain) {
				if (activeImages.length > 0) {
					let idx = this.images.findIndex((img) => !img.delete)
					this.images[idx].main = true
				} else {
					this.images.forEach((img) => {
						img.main = false
					})
				}
				// this.images[0].main = true;
			}
			// this.images.splice(index, 1)
			this.$emit("input", this.images)
		},
		checkImage(indexImage) {
			for (let index = 0; index < this.images.length; index++) {
				this.images[index].main = false
			}
			this.images[indexImage].main = true
			this.$emit("input", this.images)
		},
		addImages() {
			this.$refs.selectableImages.click()
		},
	},
	mounted() {
		this.images = this.value
	},
	destroyed() {
		this.images = []
		this.$emit("input", this.images)
	},
	watch: {
		lengthImages() {
			this.images = this.value
		},
	},
}
</script>

<style scoped lang="scss">
.container-select-images {
	height: 20rem;
	border: 2px dashed var(--primary);
	border-radius: 8px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;
	overflow-y: hidden;
	position: relative;
	padding: 1rem;

	.image-flyer {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
</style>
