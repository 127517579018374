var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"centered":"","title":"Etapas","size":"xmd"},on:{"hidden":_vm.handleHidden,"hide":_vm.handleHide},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":_vm.info._mode != 'edit' ? 'info' : 'success'},on:{"click":_vm.saveStageTypes}},[_vm._v(" "+_vm._s(_vm.info._mode == "edit" ? "Guardar" : "Continuar")+" ")])]},proxy:true}]),model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('h2',[_c('b-form-checkbox',{staticClass:"d-inline-block pb-0",attrs:{"size":""},model:{value:(_vm.withPre),callback:function ($$v) {_vm.withPre=$$v},expression:"withPre"}}),_vm._v(" Pre - clasificación "),(_vm.withPre)?_c('b-button',{staticClass:"btn-icon ml-1 p-50",attrs:{"variant":"flat-success","size":"sm"},on:{"click":function($event){return _vm.addType(1)}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"20"}})],1):_vm._e()],1),_c('hr'),_c('ValidationObserver',{ref:"form"},[(_vm.withPre)?_c('b-table-simple',{attrs:{"small":"","sticky-header":"10rem"}},[_c('b-thead',{staticClass:"text-center"},[_c('b-tr',[_c('b-th',{staticStyle:{"width":"8rem"}},[_vm._v("COD")]),_c('b-th',{staticStyle:{"width":"5rem"}},[_vm._v("GALLOS")]),_c('b-th',{staticStyle:{"width":"10rem"}},[_vm._v("COSTO")]),_c('b-th',{staticStyle:{"width":"15rem"}},[_vm._v("TIPO")]),_c('b-th',{staticStyle:{"width":"12rem"}},[_vm._v("METODO")]),_c('b-th',{staticStyle:{"width":"6rem"}},[_vm._v("PESO")]),_c('b-th',{staticStyle:{"width":"8rem"}},[_vm._v("ACCIONES")])],1)],1),_c('b-tbody',{staticClass:"text-center"},[_vm._l((_vm.pre),function(row,i){return [(!row.delete)?_c('b-tr',{key:i},[_c('b-td',{staticStyle:{"min-width":"8rem"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors[0] ? 'border-danger' : '',attrs:{"placeholder":"Escribir alias"},model:{value:(row.name),callback:function ($$v) {_vm.$set(row, "name", $$v)},expression:"row.name"}})]}}],null,true)})],1),_c('b-td',{staticStyle:{"min-width":"5rem"}},[_c('ValidationProvider',{attrs:{"rules":"required|zero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors[0] ? 'border-danger' : '',attrs:{"type":"number"},model:{value:(row.required),callback:function ($$v) {_vm.$set(row, "required", _vm._n($$v))},expression:"row.required"}})]}}],null,true)})],1),_c('b-td',{staticStyle:{"min-width":"10rem"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Money',_vm._b({staticClass:"form-control border-primary mr-50",class:errors[0] ? 'border-danger' : '',style:({
											width: '100%',
											textAlign: 'center',
										}),attrs:{"placeholder":"Agregar precio"},model:{value:(row.price),callback:function ($$v) {_vm.$set(row, "price", $$v)},expression:"row.price"}},'Money',_vm.money,false))]}}],null,true)})],1),_c('b-td',{staticStyle:{"min-width":"15rem"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-select',{class:errors[0] ? 'border-danger' : '',attrs:{"options":_vm.typeOpts},model:{value:(row.type),callback:function ($$v) {_vm.$set(row, "type", $$v)},expression:"row.type"}})]}}],null,true)})],1),_c('b-td',{staticStyle:{"min-width":"12rem"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-select',{class:errors[0] ? 'border-danger' : '',attrs:{"options":_vm.methodOpts},on:{"change":function($event){row.weight = null}},model:{value:(row.method),callback:function ($$v) {_vm.$set(row, "method", $$v)},expression:"row.method"}})]}}],null,true)})],1),_c('b-td',{staticStyle:{"min-width":"6rem"}},[(row.method != 2)?_c('b-form-input',{attrs:{"disabled":""}}):_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-input',{class:errors[0] ? 'border-danger' : '',attrs:{"type":"number"},model:{value:(row.weight),callback:function ($$v) {_vm.$set(row, "weight", $$v)},expression:"row.weight"}})]}}],null,true)})],1),_c('b-td',{staticStyle:{"min-width":"8rem"}},[_c('b-button',{staticClass:"btn-icon p-50",attrs:{"variant":"outline-danger","size":"sm"},on:{"click":function($event){return _vm.removeType(1, i)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon","size":"20"}})],1)],1)],1):_vm._e()]}),_c('b-tr',[(_vm.pre.length == 0)?_c('b-td',{attrs:{"colspan":"7"}},[_vm._v("No hay datos para mostrar")]):_vm._e()],1)],2)],1):_vm._e(),_c('h2',[_vm._v(" Clasificación "),_c('b-button',{staticClass:"btn-icon ml-1 p-50",attrs:{"variant":"flat-success","size":"sm"},on:{"click":function($event){return _vm.addType(2)}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"20"}})],1)],1),_c('b-table-simple',{attrs:{"small":"","sticky-header":"10rem"}},[_c('b-thead',{staticClass:"text-center"},[_c('b-tr',[_c('b-th',{staticStyle:{"width":"8rem"}},[_vm._v("COD")]),_c('b-th',{staticStyle:{"width":"5rem"}},[_vm._v("GALLOS")]),_c('b-th',{staticStyle:{"width":"10rem"}},[_vm._v("COSTO")]),_c('b-th',{staticStyle:{"width":"15rem"}},[_vm._v("TIPO")]),_c('b-th',{staticStyle:{"width":"12rem"}},[_vm._v("METODO")]),_c('b-th',{staticStyle:{"width":"6rem"}},[_vm._v("PESO")]),_c('b-th',{staticStyle:{"width":"8rem"}},[_vm._v("ACCIONES")])],1)],1),_c('b-tbody',{staticClass:"text-center"},[_vm._l((_vm.clasi),function(row,i){return [(!row.delete)?_c('b-tr',{key:i},[_c('b-td',{staticStyle:{"min-width":"8rem"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-input',{class:errors[0] ? 'border-danger' : '',attrs:{"placeholder":"Escribir alias"},model:{value:(row.name),callback:function ($$v) {_vm.$set(row, "name", $$v)},expression:"row.name"}})]}}],null,true)})],1),_c('b-td',{staticStyle:{"min-width":"5rem"}},[_c('ValidationProvider',{attrs:{"rules":"required|zero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-input',{class:errors[0] ? 'border-danger' : '',attrs:{"type":"number"},model:{value:(row.required),callback:function ($$v) {_vm.$set(row, "required", _vm._n($$v))},expression:"row.required"}})]}}],null,true)})],1),_c('b-td',{staticStyle:{"min-width":"10rem"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('Money',_vm._b({staticClass:"form-control border-primary mr-50",class:errors[0] ? 'border-danger' : '',style:({
											width: '100%',
											textAlign: 'center',
										}),attrs:{"placeholder":"Agregar precio"},model:{value:(row.price),callback:function ($$v) {_vm.$set(row, "price", $$v)},expression:"row.price"}},'Money',_vm.money,false))]}}],null,true)})],1),_c('b-td',{staticStyle:{"min-width":"15rem"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-select',{class:errors[0] ? 'border-danger' : '',attrs:{"options":_vm.typeOpts},model:{value:(row.type),callback:function ($$v) {_vm.$set(row, "type", $$v)},expression:"row.type"}})]}}],null,true)})],1),_c('b-td',{staticStyle:{"min-width":"12rem"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-select',{class:errors[0] ? 'border-danger' : '',attrs:{"options":_vm.methodOpts},on:{"change":function($event){row.weight = null}},model:{value:(row.method),callback:function ($$v) {_vm.$set(row, "method", $$v)},expression:"row.method"}})]}}],null,true)})],1),_c('b-td',{staticStyle:{"min-width":"6rem"}},[(row.method != 2)?_c('b-form-input',{attrs:{"disabled":""}}):_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-input',{class:errors[0] ? 'border-danger' : '',attrs:{"type":"number"},model:{value:(row.weight),callback:function ($$v) {_vm.$set(row, "weight", $$v)},expression:"row.weight"}})]}}],null,true)})],1),_c('b-td',{staticStyle:{"min-width":"8rem"}},[_c('b-button',{staticClass:"btn-icon p-50",attrs:{"variant":"outline-danger","size":"sm"},on:{"click":function($event){return _vm.removeType(2, i)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon","size":"20"}})],1)],1)],1):_vm._e()]}),_c('b-tr',[(_vm.clasi.length == 0)?_c('b-td',{attrs:{"colspan":"7"}},[_vm._v("No hay datos para mostrar")]):_vm._e()],1)],2)],1),_c('hr'),_c('h2',[_vm._v(" Semifinal "),_c('b-button',{staticClass:"btn-icon ml-1 p-50",attrs:{"variant":"flat-success","size":"sm"},on:{"click":function($event){return _vm.addType(3)}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"20"}})],1)],1),_c('b-table-simple',{attrs:{"small":"","sticky-header":"10rem"}},[_c('b-thead',{staticClass:"text-center"},[_c('b-tr',[_c('b-th',{staticStyle:{"width":"8rem"}},[_vm._v("COD")]),_c('b-th',{staticStyle:{"width":"5rem"}},[_vm._v("GALLOS")]),_c('b-th',{staticStyle:{"width":"10rem"}},[_vm._v("COSTO")]),_c('b-th',{staticStyle:{"width":"15rem"}},[_vm._v("TIPO")]),_c('b-th',{staticStyle:{"width":"12rem"}},[_vm._v("METODO")]),_c('b-th',{staticStyle:{"width":"6rem"}},[_vm._v("PESO")]),_c('b-th',{staticStyle:{"width":"8rem"}},[_vm._v("ACCIONES")])],1)],1),_c('b-tbody',{staticClass:"text-center"},[_vm._l((_vm.semi),function(row,i){return [(!row.delete)?_c('b-tr',{key:i},[_c('b-td',{staticStyle:{"min-width":"8rem"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-input',{class:errors[0] ? 'border-danger' : '',attrs:{"placeholder":"Escribir alias"},model:{value:(row.name),callback:function ($$v) {_vm.$set(row, "name", $$v)},expression:"row.name"}})]}}],null,true)})],1),_c('b-td',{staticStyle:{"min-width":"5rem"}},[_c('ValidationProvider',{attrs:{"rules":"required|zero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-input',{class:errors[0] ? 'border-danger' : '',attrs:{"type":"number"},model:{value:(row.required),callback:function ($$v) {_vm.$set(row, "required", _vm._n($$v))},expression:"row.required"}})]}}],null,true)})],1),_c('b-td',{staticStyle:{"min-width":"10rem"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('Money',_vm._b({staticClass:"form-control border-primary mr-50",class:errors[0] ? 'border-danger' : '',style:({
											width: '100%',
											textAlign: 'center',
										}),attrs:{"placeholder":"Agregar precio"},model:{value:(row.price),callback:function ($$v) {_vm.$set(row, "price", $$v)},expression:"row.price"}},'Money',_vm.money,false))]}}],null,true)})],1),_c('b-td',{staticStyle:{"min-width":"15rem"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-select',{class:errors[0] ? 'border-danger' : '',attrs:{"options":_vm.typeOpts},model:{value:(row.type),callback:function ($$v) {_vm.$set(row, "type", $$v)},expression:"row.type"}})]}}],null,true)})],1),_c('b-td',{staticStyle:{"min-width":"12rem"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-select',{class:errors[0] ? 'border-danger' : '',attrs:{"options":_vm.methodOpts},on:{"change":function($event){row.weight = null}},model:{value:(row.method),callback:function ($$v) {_vm.$set(row, "method", $$v)},expression:"row.method"}})]}}],null,true)})],1),_c('b-td',{staticStyle:{"min-width":"6rem"}},[(row.method != 2)?_c('b-form-input',{attrs:{"disabled":""}}):_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-input',{class:errors[0] ? 'border-danger' : '',attrs:{"type":"number"},model:{value:(row.weight),callback:function ($$v) {_vm.$set(row, "weight", $$v)},expression:"row.weight"}})]}}],null,true)})],1),_c('b-td',{staticStyle:{"min-width":"8rem"}},[_c('b-button',{staticClass:"btn-icon p-50",attrs:{"variant":"outline-danger","size":"sm"},on:{"click":function($event){return _vm.removeType(3, i)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon","size":"20"}})],1)],1)],1):_vm._e()]}),_c('b-tr',[(_vm.semi.length == 0)?_c('b-td',{attrs:{"colspan":"7"}},[_vm._v("No hay datos para mostrar")]):_vm._e()],1)],2)],1),_c('hr'),_c('h2',[_vm._v("Final")]),_c('b-table-simple',{attrs:{"small":"","sticky-header":"10rem"}},[_c('b-thead',{staticClass:"text-center"},[_c('b-tr',[_c('b-th',{staticStyle:{"width":"5rem"}},[_vm._v("GALLOS")]),_c('b-th',{staticStyle:{"width":"10rem"}},[_vm._v("COSTO")]),_c('b-th',{staticStyle:{"width":"15rem"}},[_vm._v("TIPO")]),_c('b-th',{staticStyle:{"width":"12rem"}},[_vm._v("METODO")]),_c('b-th',{staticStyle:{"width":"6rem"}},[_vm._v("PESO")])],1)],1),_c('b-tbody',{staticClass:"text-center"},[_vm._l((_vm.final),function(row,i){return [(!row.delete)?_c('b-tr',{key:i},[_c('b-td',{staticStyle:{"min-width":"5rem"}},[_c('ValidationProvider',{attrs:{"rules":_vm.validFinal(row) ? 'required|zero' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-input',{class:errors[0] ? 'border-danger' : '',attrs:{"type":"number"},model:{value:(row.required),callback:function ($$v) {_vm.$set(row, "required", _vm._n($$v))},expression:"row.required"}})]}}],null,true)})],1),_c('b-td',{staticStyle:{"min-width":"10rem"}},[_c('ValidationProvider',{attrs:{"rules":_vm.validFinal(row) ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('Money',_vm._b({staticClass:"form-control border-primary mr-50",class:errors[0] ? 'border-danger' : '',style:({
											width: '100%',
											textAlign: 'center',
										}),attrs:{"placeholder":"Agregar precio"},model:{value:(row.price),callback:function ($$v) {_vm.$set(row, "price", $$v)},expression:"row.price"}},'Money',_vm.money,false))]}}],null,true)})],1),_c('b-td',{staticStyle:{"min-width":"15rem"}},[_c('ValidationProvider',{attrs:{"rules":_vm.validFinal(row) ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-select',{class:errors[0] ? 'border-danger' : '',attrs:{"options":_vm.typeOpts},model:{value:(row.type),callback:function ($$v) {_vm.$set(row, "type", $$v)},expression:"row.type"}})]}}],null,true)})],1),_c('b-td',{staticStyle:{"min-width":"12rem"}},[_c('ValidationProvider',{attrs:{"rules":_vm.validFinal(row) ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-select',{class:errors[0] ? 'border-danger' : '',attrs:{"options":_vm.methodOpts},on:{"change":function($event){row.weight = null}},model:{value:(row.method),callback:function ($$v) {_vm.$set(row, "method", $$v)},expression:"row.method"}})]}}],null,true)})],1),_c('b-td',{staticStyle:{"min-width":"6rem"}},[(row.method != 2)?_c('b-form-input',{attrs:{"disabled":""}}):_c('ValidationProvider',{attrs:{"rules":_vm.validFinal(row) ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-input',{class:errors[0] ? 'border-danger' : '',attrs:{"type":"number"},model:{value:(row.weight),callback:function ($$v) {_vm.$set(row, "weight", $$v)},expression:"row.weight"}})]}}],null,true)})],1)],1):_vm._e()]}),_c('b-tr',[(_vm.final.length == 0)?_c('b-td',{attrs:{"colspan":"7"}},[_vm._v("No hay datos para mostrar")]):_vm._e()],1)],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }