// import { amgApi } from "@/service/axios";

import axios from "@/axios";

const url = "/api/tournament/general";

class GeneralTournamentService {
	async index() {
		try {
			const { data } = await axios.get(url);
			return data;
		} catch (error) {
			console.log("error", error);
		}
	}

	async store({ name }) {
		try {
			const data = await axios.post(url, {
				name,
			});x
			return data;
		} catch (error) {
			console.log("error", error);
		}
	}

	async indexImages() {
		try {
			const { data } = await axios.get("/api/tournament/avatars/");
			return data;
		} catch (error) {
			console.log("error", error);
		}
	}
}
export default new GeneralTournamentService();
