<template>
	<b-modal centered title="Etapas" size="xmd" v-model="isActive" @hidden="handleHidden" @hide="handleHide">
		<b-overlay :show="isLoading">
			<h2>
				<b-form-checkbox class="d-inline-block pb-0" size="" v-model="withPre" />
				Pre - clasificación
				<b-button
					class="btn-icon ml-1 p-50"
					variant="flat-success"
					size="sm"
					@click="addType(1)"
					v-if="withPre"
				>
					<feather-icon icon="PlusIcon" size="20" />
				</b-button>
			</h2>
			<hr />
			<ValidationObserver ref="form">
				<b-table-simple small sticky-header="10rem" v-if="withPre">
					<b-thead class="text-center">
						<b-tr>
							<b-th style="width: 8rem">COD</b-th>
							<b-th style="width: 5rem">GALLOS</b-th>
							<b-th style="width: 10rem">COSTO</b-th>
							<b-th style="width: 15rem">TIPO</b-th>
							<b-th style="width: 12rem">METODO</b-th>
							<b-th style="width: 6rem">PESO</b-th>
							<b-th style="width: 8rem">ACCIONES</b-th>
						</b-tr>
					</b-thead>
					<b-tbody class="text-center">
						<template v-for="(row, i) in pre">
							<b-tr :key="i" v-if="!row.delete">
								<b-td style="min-width: 8rem">
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-form-input
											:class="errors[0] ? 'border-danger' : ''"
											placeholder="Escribir alias"
											v-model="row.name"
										/>
									</ValidationProvider>
								</b-td>
								<b-td style="min-width: 5rem">
									<ValidationProvider rules="required|zero" v-slot="{ errors }">
										<b-form-input
											:class="errors[0] ? 'border-danger' : ''"
											type="number"
											v-model.number="row.required"
										/>
									</ValidationProvider>
								</b-td>
								<b-td style="min-width: 10rem">
									<ValidationProvider rules="required" v-slot="{ errors }">
										<Money
											:class="errors[0] ? 'border-danger' : ''"
											v-bind="money"
											v-model="row.price"
											class="form-control border-primary mr-50"
											placeholder="Agregar precio"
											:style="{
												width: '100%',
												textAlign: 'center',
											}"
										/>
									</ValidationProvider>
								</b-td>
								<b-td style="min-width: 15rem">
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-form-select
											:class="errors[0] ? 'border-danger' : ''"
											v-model="row.type"
											:options="typeOpts"
										/>
									</ValidationProvider>
								</b-td>
								<b-td style="min-width: 12rem">
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-form-select
											:class="errors[0] ? 'border-danger' : ''"
											v-model="row.method"
											:options="methodOpts"
											@change="row.weight = null"
										/>
									</ValidationProvider>
								</b-td>
								<b-td style="min-width: 6rem">
									<b-form-input disabled v-if="row.method != 2" />
									<ValidationProvider rules="required" v-slot="{ errors }" v-else>
										<b-form-input
											:class="errors[0] ? 'border-danger' : ''"
											type="number"
											v-model="row.weight"
										/>
									</ValidationProvider>
								</b-td>
								<b-td style="min-width: 8rem">
									<b-button
										class="btn-icon p-50"
										variant="outline-danger"
										size="sm"
										@click="removeType(1, i)"
									>
										<feather-icon icon="TrashIcon" size="20" />
									</b-button>
								</b-td>
							</b-tr>
						</template>
						<b-tr><b-td colspan="7" v-if="pre.length == 0">No hay datos para mostrar</b-td></b-tr>
					</b-tbody>
				</b-table-simple>
				<h2>
					Clasificación
					<b-button class="btn-icon ml-1 p-50" variant="flat-success" size="sm" @click="addType(2)">
						<feather-icon icon="PlusIcon" size="20" />
					</b-button>
				</h2>
				<b-table-simple small sticky-header="10rem">
					<b-thead class="text-center">
						<b-tr>
							<b-th style="width: 8rem">COD</b-th>
							<b-th style="width: 5rem">GALLOS</b-th>
							<b-th style="width: 10rem">COSTO</b-th>
							<b-th style="width: 15rem">TIPO</b-th>
							<b-th style="width: 12rem">METODO</b-th>
							<b-th style="width: 6rem">PESO</b-th>
							<b-th style="width: 8rem">ACCIONES</b-th>
						</b-tr>
					</b-thead>
					<b-tbody class="text-center">
						<template v-for="(row, i) in clasi">
							<b-tr :key="i" v-if="!row.delete">
								<b-td style="min-width: 8rem">
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-form-input
											:class="errors[0] ? 'border-danger' : ''"
											placeholder="Escribir alias"
											v-model="row.name"
										/>
									</ValidationProvider>
								</b-td>
								<b-td style="min-width: 5rem">
									<ValidationProvider rules="required|zero" v-slot="{ errors }">
										<b-form-input
											:class="errors[0] ? 'border-danger' : ''"
											type="number"
											v-model.number="row.required"
										/>
									</ValidationProvider>
								</b-td>
								<b-td style="min-width: 10rem">
									<ValidationProvider rules="required" v-slot="{ errors }">
										<Money
											:class="errors[0] ? 'border-danger' : ''"
											v-bind="money"
											v-model="row.price"
											class="form-control border-primary mr-50"
											placeholder="Agregar precio"
											:style="{
												width: '100%',
												textAlign: 'center',
											}"
										/>
									</ValidationProvider>
								</b-td>
								<b-td style="min-width: 15rem">
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-form-select
											:class="errors[0] ? 'border-danger' : ''"
											v-model="row.type"
											:options="typeOpts"
										/>
									</ValidationProvider>
								</b-td>
								<b-td style="min-width: 12rem">
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-form-select
											:class="errors[0] ? 'border-danger' : ''"
											v-model="row.method"
											:options="methodOpts"
											@change="row.weight = null"
										/>
									</ValidationProvider>
								</b-td>
								<b-td style="min-width: 6rem">
									<b-form-input disabled v-if="row.method != 2" />
									<ValidationProvider rules="required" v-slot="{ errors }" v-else>
										<b-form-input
											:class="errors[0] ? 'border-danger' : ''"
											type="number"
											v-model="row.weight"
										/>
									</ValidationProvider>
								</b-td>
								<b-td style="min-width: 8rem">
									<b-button
										class="btn-icon p-50"
										variant="outline-danger"
										size="sm"
										@click="removeType(2, i)"
									>
										<feather-icon icon="TrashIcon" size="20" />
									</b-button>
								</b-td>
							</b-tr>
						</template>
						<b-tr><b-td colspan="7" v-if="clasi.length == 0">No hay datos para mostrar</b-td></b-tr>
					</b-tbody>
				</b-table-simple>
				<hr />
				<h2>
					Semifinal
					<b-button class="btn-icon ml-1 p-50" variant="flat-success" size="sm" @click="addType(3)">
						<feather-icon icon="PlusIcon" size="20" />
					</b-button>
				</h2>
				<b-table-simple small sticky-header="10rem">
					<b-thead class="text-center">
						<b-tr>
							<b-th style="width: 8rem">COD</b-th>
							<b-th style="width: 5rem">GALLOS</b-th>
							<b-th style="width: 10rem">COSTO</b-th>
							<b-th style="width: 15rem">TIPO</b-th>
							<b-th style="width: 12rem">METODO</b-th>
							<b-th style="width: 6rem">PESO</b-th>
							<b-th style="width: 8rem">ACCIONES</b-th>
						</b-tr>
					</b-thead>
					<b-tbody class="text-center">
						<template v-for="(row, i) in semi">
							<b-tr :key="i" v-if="!row.delete">
								<b-td style="min-width: 8rem">
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-form-input
											:class="errors[0] ? 'border-danger' : ''"
											placeholder="Escribir alias"
											v-model="row.name"
										/>
									</ValidationProvider>
								</b-td>
								<b-td style="min-width: 5rem">
									<ValidationProvider rules="required|zero" v-slot="{ errors }">
										<b-form-input
											:class="errors[0] ? 'border-danger' : ''"
											type="number"
											v-model.number="row.required"
										/>
									</ValidationProvider>
								</b-td>
								<b-td style="min-width: 10rem">
									<ValidationProvider rules="required" v-slot="{ errors }">
										<Money
											:class="errors[0] ? 'border-danger' : ''"
											v-bind="money"
											v-model="row.price"
											class="form-control border-primary mr-50"
											placeholder="Agregar precio"
											:style="{
												width: '100%',
												textAlign: 'center',
											}"
										/>
									</ValidationProvider>
								</b-td>
								<b-td style="min-width: 15rem">
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-form-select
											:class="errors[0] ? 'border-danger' : ''"
											v-model="row.type"
											:options="typeOpts"
										/>
									</ValidationProvider>
								</b-td>
								<b-td style="min-width: 12rem">
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-form-select
											:class="errors[0] ? 'border-danger' : ''"
											v-model="row.method"
											:options="methodOpts"
											@change="row.weight = null"
										/>
									</ValidationProvider>
								</b-td>
								<b-td style="min-width: 6rem">
									<b-form-input disabled v-if="row.method != 2" />
									<ValidationProvider rules="required" v-slot="{ errors }" v-else>
										<b-form-input
											:class="errors[0] ? 'border-danger' : ''"
											type="number"
											v-model="row.weight"
										/>
									</ValidationProvider>
								</b-td>
								<b-td style="min-width: 8rem">
									<b-button
										class="btn-icon p-50"
										variant="outline-danger"
										size="sm"
										@click="removeType(3, i)"
									>
										<feather-icon icon="TrashIcon" size="20" />
									</b-button>
								</b-td>
							</b-tr>
						</template>
						<b-tr><b-td colspan="7" v-if="semi.length == 0">No hay datos para mostrar</b-td></b-tr>
					</b-tbody>
				</b-table-simple>
				<hr />
				<h2>Final</h2>
				<b-table-simple small sticky-header="10rem">
					<b-thead class="text-center">
						<b-tr>
							<b-th style="width: 5rem">GALLOS</b-th>
							<b-th style="width: 10rem">COSTO</b-th>
							<b-th style="width: 15rem">TIPO</b-th>
							<b-th style="width: 12rem">METODO</b-th>
							<b-th style="width: 6rem">PESO</b-th>
						</b-tr>
					</b-thead>

					<b-tbody class="text-center">
						<template v-for="(row, i) in final">
							<b-tr :key="i" v-if="!row.delete">
								<b-td style="min-width: 5rem">
									<ValidationProvider
										:rules="validFinal(row) ? 'required|zero' : 'required'"
										v-slot="{ errors }"
									>
										<b-form-input
											:class="errors[0] ? 'border-danger' : ''"
											type="number"
											v-model.number="row.required"
										/>
									</ValidationProvider>
								</b-td>
								<b-td style="min-width: 10rem">
									<ValidationProvider :rules="validFinal(row) ? 'required' : ''" v-slot="{ errors }">
										<Money
											:class="errors[0] ? 'border-danger' : ''"
											v-bind="money"
											v-model="row.price"
											class="form-control border-primary mr-50"
											placeholder="Agregar precio"
											:style="{
												width: '100%',
												textAlign: 'center',
											}"
										/>
									</ValidationProvider>
								</b-td>
								<b-td style="min-width: 15rem">
									<ValidationProvider :rules="validFinal(row) ? 'required' : ''" v-slot="{ errors }">
										<b-form-select
											:class="errors[0] ? 'border-danger' : ''"
											v-model="row.type"
											:options="typeOpts"
										/>
									</ValidationProvider>
								</b-td>
								<b-td style="min-width: 12rem">
									<ValidationProvider :rules="validFinal(row) ? 'required' : ''" v-slot="{ errors }">
										<b-form-select
											:class="errors[0] ? 'border-danger' : ''"
											v-model="row.method"
											:options="methodOpts"
											@change="row.weight = null"
										/>
									</ValidationProvider>
								</b-td>
								<b-td style="min-width: 6rem">
									<b-form-input disabled v-if="row.method != 2" />
									<ValidationProvider
										:rules="validFinal(row) ? 'required' : ''"
										v-slot="{ errors }"
										v-else
									>
										<b-form-input
											:class="errors[0] ? 'border-danger' : ''"
											type="number"
											v-model="row.weight"
										/>
									</ValidationProvider>
								</b-td>
							</b-tr>
						</template>
						<b-tr><b-td colspan="7" v-if="final.length == 0">No hay datos para mostrar</b-td></b-tr>
					</b-tbody>
				</b-table-simple>
			</ValidationObserver>
		</b-overlay>
		<template #modal-footer>
			<b-button :variant="info._mode != 'edit' ? 'info' : 'success'" @click="saveStageTypes">
				{{ info._mode == "edit" ? "Guardar" : "Continuar" }}
			</b-button>
		</template>
	</b-modal>
</template>

<script>
import { Money } from "v-money"
import _ from "lodash"
import tournamentService from "@/services/tournament/default.service"

export default {
	components: { Money },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		heads: ["Nombre", "Costo", "Requeridos", "Metodo", "Peso", "Tipo", "Acciones"],
		pre: [],
		clasi: [],
		semi: [],
		final: [],
		//
		withPre: false,
		methodOpts: [],
		typeOpts: [],
		closeConfirmed: false,
		money: {
			decimal: ".",
			thousands: ",",
			prefix: "S./ ",
			suffix: "",
			precision: 2,
			masked: false,
		},
		cleanPre: [],
		cleanClasi: [],
		cleanSemi: [],
		cleanFinal: [],
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.pre = []
			this.clasi = []
			this.semi = []
			this.final = []
			this.cleanPre = []
			this.cleanClasi = []
			this.cleanSemi = []
			this.cleanFinal = []
			this.withPre = false
			this.$emit("closing")
		},
		async handleHide(e) {
			if (this.closeConfirmed) {
				return
			} else {
				// cuando no hay cambios
				if (this.noChanges()) {
					return
				}
				e.preventDefault()
				const { isConfirmed } = await this.showConfirmSwal({ title: "Los cambios no se guardaran" })
				if (isConfirmed) {
					this.closeConfirmed = true
					this.handleHidden()
				}
			}
		},
		noChanges() {
			// hacer copia del array final para eliminar el que se agrega automaticamente si no se llena
			let sanitizedFinal = this.final.filter((t) => {
				return !!t.method || !!t.type || !!t.price || t.required
			})
			let samePre = _(this.cleanPre).xorWith(this.pre, _.isEqual).isEmpty()
			let sameClasi = _(this.cleanClasi).xorWith(this.clasi, _.isEqual).isEmpty()
			let sameSemi = _(this.cleanSemi).xorWith(this.semi, _.isEqual).isEmpty()
			let sameFinal = _(this.cleanFinal).xorWith(sanitizedFinal, _.isEqual).isEmpty()

			return samePre && sameClasi && sameSemi && sameFinal
		},
		parseStage() {
			let stages = []
			if (this.info._mode == "create") {
				// para no afectar la prop directamente y limpiar eliminados
				stages = this.info.stages.filter((s) => !s.delete).map((s) => ({ ...s }))
			} else {
				stages = this.info.stages.map((s) => ({ ...s }))
			}

			stages.forEach((s) => {
				s.name = s.code
				s.type = s.type_id
				switch (s.stage_id) {
					case 1:
						this.withPre = true
						this.pre.push(s)
						break
					case 2:
						this.clasi.push(s)
						break
					case 3:
						this.semi.push(s)
						break
					case 4:
						this.final.push(s)
						break
				}
			})
			this.cleanPre = this.pre.map((t) => ({ ...t }))
			this.cleanClasi = this.clasi.map((t) => ({ ...t }))
			this.cleanSemi = this.semi.map((t) => ({ ...t }))
			this.cleanFinal = this.final.map((t) => ({ ...t }))
			// agregar 1 final por defecto
			if (this.final.length == 0) {
				this.final.push({
					name: "F1",
					price: 0,
					required: 0,
					method: null,
					weight: null,
					type: null,
					delete: false,
				})
			}
		},
		async getTypes() {
			this.isLoading = true
			this.methodOpts = [
				{ text: "TAPADA", value: 1 },
				{ text: "AL PESO", value: 2 },
			]
			const { data } = await tournamentService.getTournamentTypes()
			this.typeOpts = data.data.map((t) => ({
				text: t.name,
				value: t.id,
			}))
			this.isLoading = false
		},
		addType(stage) {
			let base = { name: "", price: 0, required: 0, method: null, weight: null, type: null, delete: false }
			let visibleLength = 1
			switch (stage) {
				case 1:
					visibleLength = this.pre.filter((t) => !t.delete).length + 1
					do {
						base.name = "P" + visibleLength
						visibleLength++
					} while (this.pre.some((t) => t.name == base.name))
					this.pre.push(base)
					break
				case 2:
					visibleLength = this.clasi.filter((t) => !t.delete).length + 1
					do {
						base.name = "C" + visibleLength
						visibleLength++
					} while (this.clasi.some((t) => t.name == base.name))
					this.clasi.push(base)
					break
				case 3:
					visibleLength = this.semi.filter((t) => !t.delete).length + 1
					do {
						base.name = "S" + visibleLength
						visibleLength++
					} while (this.semi.some((t) => t.name == base.name))
					this.semi.push(base)
					break
				case 4:
					visibleLength = this.final.filter((t) => !t.delete).length + 1
					do {
						base.name = "F" + visibleLength
						visibleLength++
					} while (this.final.some((t) => t.name == base.name))
					this.final.push(base)
					break
			}
		},
		removeType(stage, idx) {
			switch (stage) {
				case 1:
					this.pre[idx].delete = true
					// this.pre.splice(idx, 1)
					break
				case 2:
					this.clasi[idx].delete = true
					// this.clasi.splice(idx, 1)
					break
				case 3:
					this.semi[idx].delete = true
					// this.semi.splice(idx, 1)
					break
				case 4:
					this.final[idx].delete = true
					// this.final.splice(idx, 1)
					break
			}
		},
		generateStages() {
			let preclasified = []
			if (this.withPre) {
				preclasified = this.pre.map((t) => {
					return {
						stage_id: 1,
						status: 1,
						code: t.name,
						price: t.price,
						required: t.required,
						method: t.method,
						weight: t.weight,
						type_id: t.type,
						id: t.id,
						delete: t.delete,
					}
				})
			}
			let clasified = this.clasi.map((t) => {
				return {
					stage_id: 2,
					status: 1,
					code: t.name,
					price: t.price,
					required: t.required,
					method: t.method,
					weight: t.weight,
					type_id: t.type,
					id: t.id,
					delete: t.delete,
				}
			})
			let semifinal = this.semi.map((t) => {
				return {
					stage_id: 3,
					status: 1,
					code: t.name,
					price: t.price,
					required: t.required,
					method: t.method,
					weight: t.weight,
					type_id: t.type,
					id: t.id,
					delete: t.delete,
				}
			})
			let final = this.final
				.map((t) => {
					return {
						stage_id: 4,
						status: 1,
						code: t.name,
						price: t.price,
						required: t.required,
						method: t.method,
						weight: t.weight,
						type_id: t.type,
						id: t.id,
						delete: t.delete,
					}
				})
				.filter((it) => this.validStageFinal(it))

			return [...preclasified, ...clasified, ...semifinal, ...final]
		},
		async saveStageTypes() {
			if (!(await this.$refs.form.validate())) return
			if (!this.noChanges()) {
				const { isConfirmed } = await this.showConfirmSwal({
					title: "Se aplicaran los cambios, esto puede afectar a las fechas",
				})
				if (!isConfirmed) return
			}
			this.closeConfirmed = true
			this.$emit("new-types", this.generateStages())
			this.handleHidden()
		},
		validStageFinal(row) {
			if (row.method && row.required > 0 && row.type_id) {
				if (row.method == 2) {
					return row.weight ? true : false
				} else {
					return true
				}
			} else {
				return false
			}
		},
		validFinal(row) {
			return row.method || row.required > 0 || row.type || (row.method == 2 && row.weight)
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.closeConfirmed = false // perdoname dios
				this.getTypes()
				this.parseStage()
			}
		},
	},
}
</script>
