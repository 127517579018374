<template>
	<validation-observer tag="b-row" ref="form">
		<b-col cols="12">
			<validation-provider rules="required" v-slot="{ errors, valid }">
				<b-form-group label="Nombre" label-for="name">
					<b-form-input
						id="name"
						v-model="coliseum.name"
						:state="errors[0] ? false : valid ? true : null"
						@input="(val) => (coliseum.name = coliseum.name.toUpperCase())"
					/>
					<span style="color: red">{{ errors[0] }}</span>
				</b-form-group>
			</validation-provider>
		</b-col>
		<b-col cols="12">
			<b-form-group label="Ciudad" label-for="city">
				<b-form-input
					id="city"
					v-model="coliseum.city"
					@input="(val) => (coliseum.city = coliseum.city.toUpperCase())"
				/>
			</b-form-group>
		</b-col>
		<b-col cols="12">
			<b-form-group label="Direccion" label-for="address">
				<b-form-input
					id="address"
					v-model="coliseum.address"
					@input="(val) => (coliseum.address = coliseum.address.toUpperCase())"
				/>
			</b-form-group>
		</b-col>
	</validation-observer>
</template>

<script>
import ColiseumService from "./coliseum.service"
export default {
	data() {
		return {
			coliseum: {
				name: null,
				address: null,
				city: null,
				created_by: null,
			},
		}
	},
	methods: {
		async saveColiseum() {
			console.log("save")
			try {
				this.coliseum.created_by = this.currentUser.user_id
				const success = await this.$refs.form.validate()
				if (success) {
					const { data } = await ColiseumService.saveColiseum(this.coliseum)
					this.showToast("success", "top-right", "Felicidades", "CheckIcon", "Coliseo agregado correctamente")
					this.$emit("new-record", data.data.coliseum_id)
					this.$emit("refreshColiseums")
				}
			} catch (error) {}
		},
	},
}
</script>

<style></style>
